import React, { FC, useEffect, useRef, useState } from "react";
import { CircularProgress, ListItem } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import IOPDrawerWrapper from "../IOPDrawerWrapper/IOPDrawerWrapper";
import UserLink from "../UserLink/UserLink";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import { fetchMenuAction, setActiveTrailAction } from "../../redux/actions";

interface Props {
	activeMenuItem: string;
	menu: MainMenuItemInterface[];
	fetchMenu: any;
	setActiveTrail: any;
}

const IOPDrawer: FC<Props> = ({
	activeMenuItem,
	menu,
	fetchMenu,
	setActiveTrail,
}) => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const toolbarRef = useRef<HTMLDivElement>(null);
	const handleDrawerToggle = (state: boolean) => {
		setOpen(state);
	};

	/**
	 * Get menu items from the backend and save them in the store.
	 */
	useEffect(() => {
		fetchMenu();
	}, [fetchMenu]);

	/**
	 * Create an array of the active menu item and all of its parents.
	 */
	useEffect(() => {
		const activeTrail = (data: MainMenuItemInterface[], value: string) => {
			let tree: string[] = [];
			for (const item of data) {
				if (item.children.length > 0) {
					tree = activeTrail(item.children, value);
					if (tree[tree.length - 1] === value) {
						tree.push(item.id);
						return tree;
					}
				}
				if (item.id === value) {
					tree.push(item.id);
					return tree;
				}
			}
			return tree;
		};

		setActiveTrail(activeTrail(menu, activeMenuItem) || []);
	}, [activeMenuItem, menu, setActiveTrail]);

	return (
		<Drawer
			variant="permanent"
			className={clsx(classes.drawer, {
				[classes.drawerOpen]: open,
				[classes.drawerClose]: !open,
			})}
			classes={{
				paper: clsx({
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				}),
			}}
		>
			{menu.length ? (
				<Scrollbars
					className={classes.scrollBars}
					style={{ height: "calc(100vh - 136px)", overflowX: "hidden" }}
					hideTracksWhenNotNeeded={true}
				>
					{menu.map((item) => (
						<IOPDrawerWrapper
							key={item.id}
							drawerItem={item}
							depth={0}
							drawerOpen={open}
							handleDrawerToggle={handleDrawerToggle}
						/>
					))}
				</Scrollbars>
			) : (
				<ListItem>
					<CircularProgress color="secondary" />
				</ListItem>
			)}

			<Divider />

			<div
				className={clsx(classes.toolbar, {
					[classes.toolbarClosed]: !open,
				})}
				ref={toolbarRef}
			>
				<div className={classes.userLinkWrapper}>
					<UserLink />
				</div>

				<IconButton
					onClick={() => handleDrawerToggle(!open)}
					className={classes.toggleButton}
				>
					{open ? (
						<ChevronLeftIcon fontSize="large" />
					) : (
						<ChevronRightIcon fontSize="large" />
					)}
				</IconButton>
			</div>
		</Drawer>
	);
};

const drawerWidth = 400;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		menuButton: {
			marginRight: 36,
		},
		scrollBars: {
			"& > div:nth-child(2)": {
				display: "none",
			},
			"& > div:nth-child(3)": {
				left: "0",
			},
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
			whiteSpace: "nowrap",
		},
		drawerOpen: {
			justifyContent: "space-between",
			width: drawerWidth,
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		drawerClose: {
			display: "flex",
			flexDirection: "column",
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			overflowX: "hidden",
			width: theme.spacing(9) + 1,
			[theme.breakpoints.up("sm")]: {
				width: theme.spacing(9) + 1,
			},
		},
		toolbar: {
			display: "flex",
			flex: 1,
			flexDirection: "column",
			alignItems: "flex-end",
			justifyContent: "flex-end",
			padding: theme.spacing(1, 2),
			minHeight: "122px",
			position: "fixed",
			bottom: "0",
			width: drawerWidth,
			backgroundColor: "#fff",
			boxSizing: "border-box",
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
			overflow: "hidden",
		},
		toolbarClosed: {
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
			width: theme.spacing(9) + 1,
		},
		userLinkWrapper: {
			width: "100%",
		},
		toggleButton: {
			margin: "4px",
			padding: "0",
		},
	})
);

const mapStateToProps = (state: { activeMenuItem: any; menu: any }) => {
	return {
		activeMenuItem: state.activeMenuItem,
		menu: state.menu,
	};
};

const mapDispatchToProps = {
	fetchMenu: fetchMenuAction,
	setActiveTrail: setActiveTrailAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(IOPDrawer);
