import { createStyles, makeStyles, Paper, Theme } from "@material-ui/core";
import React, { FC } from "react";

interface Props {
	title: string;
	body: string;
}

const TextTile: FC<Props> = ({ title, body }) => {
	const classes = makeStyles((theme: Theme) =>
		createStyles({
			paper: {
				padding: theme.spacing(2),
				color: theme.palette.text.secondary,
				width: "100%",
			},
		})
	)();

	return (
		<Paper className={classes.paper}>
			<h2 dangerouslySetInnerHTML={{ __html: title }} />
			<div dangerouslySetInnerHTML={{ __html: body }} />
		</Paper>
	);
};

export default TextTile;
