import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {
	makeStyles,
	createStyles,
	Theme,
	MuiThemeProvider,
} from "@material-ui/core/styles";
import theme from "../../theme";
import { connect, ConnectedProps } from "react-redux";
import HoverMenu from "../HoverMenu/HoverMenu";
import IOPDrawerItem from "../IOPDrawerItem/IOPDrawerItem";
import useHrefBuilder from "../../hooks/UseHrefBuilder";
import { setActiveMenuItem } from "../../redux/actions";

const mapState = (state: {
	activeMenuItem: any;
	activeMenuTrail: string[];
}) => ({
	activeMenuItem: state.activeMenuItem,
	activeMenuTrail: state.activeMenuTrail,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
	drawerItem: MainMenuItemInterface;
	drawerOpen: boolean;
	handleDrawerToggle: (open: boolean) => void;
	depth: number;
};
const connector = connect(mapState, {});

const IOPDrawerWrapper: FC<Props> = ({
	drawerItem,
	depth,
	drawerOpen,
	handleDrawerToggle,
	activeMenuItem,
	activeMenuTrail,
}) => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const hrefBuilder = useHrefBuilder();
	const href = hrefBuilder(drawerItem);
	const inActiveTrail = activeMenuTrail.includes(drawerItem.id);
	const isActive = activeMenuItem
		? activeMenuItem === drawerItem.id
		: window.location.pathname === hrefBuilder(drawerItem);
	const [showHoverMenu, setShowHoverMenu] = useState<boolean>(false);
	const listItemTextRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (isActive) {
			setActiveMenuItem(drawerItem.id);
		}
	}, [drawerItem.id, isActive, setActiveMenuItem]);

	useEffect(() => {
		!drawerOpen && setOpen(false);
		drawerOpen && setShowHoverMenu(false);
	}, [drawerOpen]);

	const toggleCategory = () => setOpen(!open);

	const nestedDrawerItems = drawerItem.children.map(
		(item: MainMenuItemInterface) => (
			<IOPDrawerWrapper
				key={item.id}
				drawerItem={item}
				depth={depth + 1}
				drawerOpen={drawerOpen}
				handleDrawerToggle={handleDrawerToggle}
				activeMenuItem={activeMenuItem}
				activeMenuTrail={activeMenuTrail}
			/>
		)
	);

	useEffect(() => {
		if (!listItemTextRef.current) return;
		listItemTextRef.current.style.marginLeft = `${depth * theme.spacing(2)}px`;
	}, [depth]);

	const renderItem = () => {
		return (
			<ListItem
				className={
					isActive
						? clsx(classes.listItem, classes.isActive)
						: inActiveTrail
						? clsx(classes.listItem, classes.inActiveTrail)
						: classes.listItem
				}
				onMouseEnter={() => setShowHoverMenu(!drawerOpen)}
				onMouseLeave={() => setShowHoverMenu(false)}
				button
			>
				<ListItemText
					className={clsx("list-item-text", classes.listItemText)}
					ref={listItemTextRef}
				>
					<IOPDrawerItem
						item={drawerItem}
						isActive={isActive}
						handleDrawerToggle={handleDrawerToggle}
						href={href}
						toggleCategory={toggleCategory}
					/>
				</ListItemText>

				{"category" === drawerItem.type &&
					drawerOpen &&
					(open ? <ExpandLess /> : <ExpandMore />)}

				{showHoverMenu && (
					<HoverMenu
						category={drawerItem.children.length > 0 ? drawerItem.label : null}
						items={
							drawerItem.children.length > 0
								? drawerItem.children
								: [drawerItem]
						}
						showHoverMenu={showHoverMenu}
						indent={73}
						parentRef={listItemTextRef}
						handleDrawerToggle={handleDrawerToggle}
						toggleCategory={toggleCategory}
					/>
				)}
			</ListItem>
		);
	};

	return (
		<MuiThemeProvider theme={theme}>
			{renderItem()}

			{"category" === drawerItem.type ? (
				<Collapse
					in={open}
					timeout="auto"
					children={<List disablePadding={true}>{nestedDrawerItems}</List>}
				/>
			) : (
				<List disablePadding={true}>{nestedDrawerItems}</List>
			)}
		</MuiThemeProvider>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		listItem: {
			color: theme.palette.primary.main,
			paddingBottom: "0",
			paddingTop: "0",
			"&:hover": {
				backgroundColor: theme.palette.primary.main,
				color: "#fff",
				"& .list-item-text a": {
					color: "#fff",
				},
			},
		},
		listItemText: {
			marginBottom: "0",
			marginLeft: "0",
			marginTop: "0",
		},
		inActiveTrail: {
			backgroundColor: theme.palette.secondary.light,
			"& .list-item-text a": { color: "#fff" },
		},
		isActive: {
			backgroundColor: theme.palette.secondary.main,
			"& .list-item-text a": { color: "#fff" },
		},
	})
);

export default connector(IOPDrawerWrapper);
