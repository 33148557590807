import React, { FC } from "react";
import "./svgxuse";
import "./RaboIcon";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

interface Props {
	color: string;
	name: string;
}

const RaboIcon: FC<Props> = ({ name, color }) => {
	const classes = makeStyles((theme: Theme) =>
		createStyles({
			iconWrapper: {
				backgroundColor: "#fff",
				border: `1px solid ${color}`,
				height: "24px",
				borderRadius: "50%",
				padding: "6px",
				width: "24px",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			},
			icon: {
				display: "inline-block",
				height: "18px",
				strokeWidth: "0",
				stroke: color,
				fill: "currentColor",
				width: "24px",
			},
		})
	)();

	return (
		<div className={classes.iconWrapper}>
			<img src={`/SVG/${name}.svg`} alt="React Logo" className={classes.icon} />
		</div>
	);
};

export default RaboIcon;
