import React, { FC } from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { BugReport } from "@material-ui/icons";

interface Props {
	type: string;
	setType: (a: string) => void;
}

const FeedbackTypeButtons: FC<Props> = ({ type, setType }) => {
	const classes = makeStyles((theme: Theme) =>
		createStyles({
			buttonLabel: {
				marginLeft: theme.spacing(0.75),
			},
			label: {
				marginBottom: theme.spacing(0.5),
			},
		})
	)();

	const handleChange = (
		event: React.MouseEvent<HTMLElement>,
		nextType: string
	) => {
		setType(nextType);
	};

	return (
		<>
			<Typography component="legend" className={classes.label}>
				Waar gaat je feedback over?
			</Typography>
			<ToggleButtonGroup value={type} exclusive onChange={handleChange}>
				<ToggleButton value="question">
					<HelpOutlineIcon />
					<div className={classes.buttonLabel}>Vraag</div>
				</ToggleButton>
				<ToggleButton value="problem">
					<BugReport />
					<div className={classes.buttonLabel}>Probleem</div>
				</ToggleButton>
				<ToggleButton value="compliment">
					<ThumbUpIcon />
					<div className={classes.buttonLabel}>Compliment</div>
				</ToggleButton>
				<ToggleButton value="complaint">
					<ThumbDownIcon />
					<div className={classes.buttonLabel}>Klacht</div>
				</ToggleButton>
				<ToggleButton value="idea">
					<EmojiObjectsIcon />
					<div className={classes.buttonLabel}>Idee</div>
				</ToggleButton>
			</ToggleButtonGroup>
		</>
	);
};

export default FeedbackTypeButtons;
