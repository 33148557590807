import React, { FC, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import IFrame from "./components/IFrame/IFrame";
import UserPage from "./components/UserPage/UserPage";
import IOPDrawer from "./components/IOPDrawer/IOPDrawer";
import { connect, ConnectedProps } from "react-redux";
import {
	createStyles,
	makeStyles,
	MuiThemeProvider,
	Theme,
} from "@material-ui/core/styles";
import theme from "./theme";
import ContentPage from "./components/ContentPage/ContentPage";
import Feedback from "./components/Feedback/Feedback";
import { Grid, LinearProgress } from "@material-ui/core";
import { fetchUserAction } from "./redux/actions";

const mapState = (state: State) => state;
const mapDispatchToProps = { fetchUser: fetchUserAction };

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & { fetchUser: () => void };
const connector = connect(mapState, mapDispatchToProps);

const App: FC<Props> = ({ user, fetchUser }) => {
	const classes = useStyles();
	const [hasReloaded, sethasReloaded] = useState<boolean>(false);

	useEffect(() => {
		setTimeout(() => sethasReloaded(true), 2000);
	}, []);

	useEffect(() => {
		fetchUser();
	}, [fetchUser]);

	useEffect(() => {
		user &&
			window.location.href !== user.userRedirect &&
			window.location.replace(user.userRedirect);
	}, [user]);

	return (
		<Router>
			<MuiThemeProvider theme={theme}>
				{user && hasReloaded ? (
					<div className={classes.root}>
						<IOPDrawer />

						<main className={classes.content}>
							<Switch>
								<Route exact path="/">
									<ContentPage />
								</Route>
								<Route path="/embed/:path">
									<IFrame type="embed" />
								</Route>
								<Route path="/instructions/:path">
									<IFrame type="instructions" />
								</Route>
								<Route path="/page/:path">
									<ContentPage />
								</Route>
								<Route path="/user">
									<UserPage />
								</Route>
							</Switch>
						</main>
						<Feedback />
					</div>
				) : (
					<Grid
						container
						spacing={0}
						alignItems="center"
						justify="center"
						style={{ minHeight: "100vh" }}
					>
						<Grid item xs={6}>
							<LinearProgress color="secondary" />
						</Grid>
					</Grid>
				)}
			</MuiThemeProvider>
			{!hasReloaded && (
				<iframe
					src="https://app.powerbi.com/autoAuthLogin?ctid=6e93a626-8aca-4dc1-9191-ce291b4b75a1"
					style={{ display: "none" }}
				/>
			)}
		</Router>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			minHeight: "100vh",
		},
		toolbar: {
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			padding: theme.spacing(0, 1),
		},
		content: {
			backgroundColor: "#f4f4f4",
			display: "flex",
			flexGrow: 1,
		},
	})
);

export default connector(App);
