import {
	ADD_MENU_ITEMS,
	SET_ACTIVE_MENU_ITEM,
	SET_ACTIVE_MENU_TRAIL,
	SET_USER,
} from "./actionTypes";
import { fetchMenu, fetchUser } from "../api/api";

export const setUserAction = (user: User | null) => ({ type: SET_USER, user });

export const fetchUserAction = () => async (
	dispatch: (arg0: { type: string; user: User }) => void
) => {
	const user = await fetchUser();
	user && dispatch(setUserAction(user));
};

export const setMenuItems = (menuItems: any) => ({
	type: ADD_MENU_ITEMS,
	menuItems,
});

export const fetchMenuAction = () => async (
	dispatch: (arg0: { type: string; menuItems: MainMenuItemInterface[] }) => void
) => {
	const menuItems = await fetchMenu();
	dispatch(setMenuItems(menuItems));
};

export const setActiveTrailAction = (activeTrail: string[]) => ({
	type: SET_ACTIVE_MENU_TRAIL,
	activeMenuTrail: activeTrail,
});

export const setActiveMenuItem = (id: string) => ({
	type: SET_ACTIVE_MENU_ITEM,
	activeMenuItem: id,
});
