import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 700,
			md: 993,
			lg: 1286,
			xl: 1579,
		},
	},
	typography: {
		fontFamily: [
			"Myriad Regular",
			'"Segoe UI"',
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
		].join(","),
	},
	palette: {
		primary: {
			main: "#000099",
		},
		secondary: {
			light: "#ffb382",
			main: "#fd6400",
		},
	},
	overrides: {
		MuiTypography: {
			body1: {
				fontFamily: [
					"Myriad Regular",
					'"Segoe UI"',
					'"Helvetica Neue"',
					"Arial",
					"sans-serif",
				].join(","),
				width: "100%",
			},
		},
		MuiListItem: {
			gutters: {
				paddingLeft: "18px",
				paddingRight: "18px",
			},
		},
	},
});
