import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { FC, useCallback } from "react";
import clsx from "clsx";
import theme from "../../theme";
import RaboIcon from "../RaboIcon/RaboIcon";
import PopoutLink from "../PopoutLink/PopoutLink";
import { NavLink } from "react-router-dom";
import DescriptionIcon from "@material-ui/icons/Description";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { connect, ConnectedProps } from "react-redux";
import { setActiveMenuItem } from "../../redux/actions";

const mapDispatchToProps = { setActiveMenuItem };
const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
	item: MainMenuItemInterface;
	isActive: boolean;
	showIcon?: boolean;
	href: string | null;
	toggleCategory?: () => void;
	handleDrawerToggle?: (a: boolean) => void;
};

const IOPDrawerItem: FC<Props> = ({
	item,
	handleDrawerToggle,
	showIcon = true,
	href,
	toggleCategory,
	setActiveMenuItem,
}) => {
	const classes = useStyles();

	const handleCategoryOnClick = () => {
		handleDrawerToggle && handleDrawerToggle(true);
		toggleCategory && toggleCategory();
	};

	const icon = showIcon && item.icon && (
		<div className={classes.icon} onClick={handleCategoryOnClick}>
			<RaboIcon name={item.icon} color={theme.palette.primary.main} />
		</div>
	);

	const handleClick = useCallback(
		(e, item) => {
			e.stopPropagation();
			setActiveMenuItem(item.id);
			handleDrawerToggle && handleDrawerToggle(false);
		},
		[handleDrawerToggle, setActiveMenuItem]
	);

	return (
		<>
			{"category" === item.type || !href ? (
				<div
					className={clsx("category", classes.category, {
						[classes.hoverItem]: !showIcon,
					})}
					onClick={toggleCategory}
				>
					<span className={classes.content}>
						{icon}
						{item.label}
					</span>
					{!showIcon && <ChevronRightIcon />}
				</div>
			) : (
				<span className={classes.linkWrapper}>
					{"external" === item.type && href ? (
						<a
							href={href}
							target="_blank"
							rel="noopener noreferrer"
							className={clsx(classes.content, classes.link, {
								[classes.hoverItem]: !showIcon,
							})}
							onClick={(e) => handleClick(e, item)}
						>
							{icon}
							{item.label}
						</a>
					) : (
						<NavLink
							to={href}
							className={clsx(classes.content, classes.link, {
								[classes.hoverItem]: !showIcon,
							})}
							onClick={(e) => handleClick(e, item)}
						>
							{icon}
							{item.label}
						</NavLink>
					)}

					<div
						className={clsx(classes.linkIcons, {
							[classes.hoverMenuIcons]: !showIcon,
						})}
					>
						{item.documentationUrl && (
							<NavLink
								to={`/instructions/${item.path}`}
								onClick={(e) => handleClick(e, item)}
							>
								<DescriptionIcon />
							</NavLink>
						)}

						{item.popoutUrl && <PopoutLink url={item.popoutUrl} />}
					</div>
				</span>
			)}
		</>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		content: {
			alignItems: "center",
			display: "flex",
		},
		icon: {
			marginRight: theme.spacing(2.5),
		},
		link: {
			display: "inline-flex",
			flexGrow: 1,
			textDecoration: "none",
			color: theme.palette.primary.main,
			paddingBottom: theme.spacing(1),
			paddingTop: theme.spacing(1),
			"&:hover": { color: "#fff" },
		},
		linkIcons: {
			marginLeft: theme.spacing(2),
			"& a": {
				color: theme.palette.primary.main,
				"&:hover": { color: "#fff" },
			},
		},
		linkWrapper: {
			alignItems: "center",
			display: "flex",
			justifyContent: "space-between",
		},
		category: {
			alignItems: "center",
			display: "flex",
			justifyContent: "space-between",
			paddingBottom: theme.spacing(1),
			paddingTop: theme.spacing(1),
		},
		hoverItem: {
			paddingBottom: "0",
			paddingTop: "0",
		},
		hoverMenuIcons: {
			marginRight: theme.spacing(2),
		},
	})
);

export default connector(IOPDrawerItem);
