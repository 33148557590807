import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Avatar,
	Card,
	CardContent,
	CardHeader,
	createStyles,
	Divider,
	List,
	ListItem,
	Theme,
	Typography,
} from "@material-ui/core";
import React, { FC } from "react";
import { connect, ConnectedProps } from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LogoutButton from "../LogoutButton/LogoutButton";
import makeStyles from "@material-ui/core/styles/makeStyles";

const mapState = (state: UserState) => ({
	user: state.user,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;
const connector = connect(mapState);

const UserPage: FC<Props> = ({ user }) => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper>
						<Card>
							<CardHeader
								avatar={
									<Avatar aria-label="recipe">
										<img
											src={user?.image}
											alt={user?.preferedName}
											className={classes.avatarImage}
										/>
									</Avatar>
								}
								title={user?.preferedName}
								subheader={user?.emailAddress}
							/>
							<CardContent>
								<Accordion>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1a-content"
										id="panel1a-header"
									>
										<Typography>Roles</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<List>
											{user?.roles.map((role, i) => (
												<ListItem key={i}>{role}</ListItem>
											))}
										</List>
									</AccordionDetails>
								</Accordion>
								<Divider />
								<div className={classes.logoutButton}>
									<LogoutButton />
								</div>
							</CardContent>
						</Card>
					</Paper>
				</Grid>
			</Grid>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			padding: theme.spacing(3),
		},
		logoutButton: {
			marginTop: theme.spacing(4),
		},
		avatarImage: {
			height: "40px",
			width: "40px",
		},
	})
);

export default connector(UserPage);
