import React, { FC } from "react";
import LaunchIcon from "@material-ui/icons/Launch";

interface Props {
	url: string;
}

const PopoutLink: FC<Props> = ({ url }) => {
	return (
		<a href={url} target="_blank" rel="noopener noreferrer">
			<LaunchIcon />
		</a>
	);
};

export default PopoutLink;
