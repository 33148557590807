import {
	createStyles,
	List,
	ListItem,
	ListSubheader,
	makeStyles,
	Paper,
	Theme,
} from "@material-ui/core";
import React, {
	FC,
	MutableRefObject,
	useEffect,
	useRef,
	useState,
} from "react";
import clsx from "clsx";
import ListItemText from "@material-ui/core/ListItemText";
import IOPDrawerItem from "../IOPDrawerItem/IOPDrawerItem";
import useHrefBuilder from "../../hooks/UseHrefBuilder";

interface Props {
	category: string | null;
	items: MainMenuItemInterface[];
	showHoverMenu: boolean;
	indent: number;
	parentRef?: MutableRefObject<HTMLDivElement | null>;
	toggleCategory?: () => void;
	handleDrawerToggle?: (a: boolean) => void;
}

const HoverMenu: FC<Props> = ({
	category,
	items,
	showHoverMenu = false,
	indent,
	parentRef,
	toggleCategory,
	handleDrawerToggle,
}) => {
	const classes = useStyles();
	const ref = useRef<HTMLDivElement>(null);
	const hrefBuilder = useHrefBuilder();
	const [showChildMenu, setShowChildMenu] = useState<string | false>(false);

	useEffect(() => {
		if (!ref.current) return;
		const hoverMenu = ref.current;
		hoverMenu.style.left = `${indent}px`;
		hoverMenu.style.display = showHoverMenu ? "block" : "none";

		if (parentRef?.current) {
			const parentPosition = parentRef.current.getBoundingClientRect();
			hoverMenu.style.top = `${parentPosition.top}px`;

			if (parentPosition.top + ref.current.offsetHeight > window.innerHeight) {
				hoverMenu.style.bottom = "0";
				hoverMenu.style.top = "auto";
			}
		} else {
			if (
				hoverMenu.getBoundingClientRect().top + hoverMenu.offsetHeight >
				window.innerHeight
			) {
				hoverMenu.style.bottom = "0";
				hoverMenu.style.top = "auto";
			}
		}
	}, [indent, showHoverMenu, parentRef]);

	const handleCategoryOnClick = () => {
		handleDrawerToggle && handleDrawerToggle(true);
		toggleCategory && toggleCategory();
	};

	return (
		<Paper square className={classes.root} ref={ref}>
			<List className={classes.list}>
				{category && (
					<ListSubheader
						component="div"
						className={classes.subHeader}
						onClick={handleCategoryOnClick}
					>
						{category}
					</ListSubheader>
				)}
				{items.map((item) => (
					<ListItem
						className={clsx({
							[classes.listItem]: true,
							[classes.linkListItem]: "category" !== item.type,
						})}
						key={item.id}
						onMouseEnter={() =>
							setShowChildMenu(item.children.length > 0 ? item.id : false)
						}
						onMouseLeave={() => setShowChildMenu(false)}
					>
						<ListItemText className={classes.listItemText}>
							<IOPDrawerItem
								item={item}
								isActive={false}
								showIcon={false}
								href={hrefBuilder(item)}
							/>
						</ListItemText>
						{showChildMenu === item.id && (
							<HoverMenu
								category={item.children.length > 0 ? item.label : null}
								items={item.children.length > 0 ? item.children : [item]}
								showHoverMenu={showHoverMenu}
								indent={ref.current ? indent + ref.current.clientWidth : indent}
							/>
						)}
					</ListItem>
				))}
			</List>
		</Paper>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			color: theme.palette.primary.main,
			display: "none",
			maxHeight: "100vh",
			overflowY: "auto",
			position: "fixed",
		},
		list: {
			padding: "0",
		},
		listItem: {
			padding: "0",
			"& span > a": {
				boxSizing: "border-box",
				height: "100%",
				width: "100%",
				paddingBottom: theme.spacing(1),
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(1),
				paddingTop: theme.spacing(1),
			},
			"& .category": {
				paddingBottom: theme.spacing(1),
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(1),
				paddingTop: theme.spacing(1),
			},
			"&:hover": {
				backgroundColor: theme.palette.primary.main,
				color: "#fff",
			},
		},
		linkListItem: {
			"& a": {
				color: theme.palette.primary.main,
				"&:hover": { color: "#fff" },
			},
			"&:hover a": {
				color: "#fff",
			},
		},
		listItemText: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
		},
		subHeader: {
			backgroundColor: "#fff",
			borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
			color: theme.palette.secondary.main,
			fontSize: "1rem",
		},
	})
);

export default HoverMenu;
