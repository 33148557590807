import React, { FC } from "react";
import Rating, { IconContainerProps } from "@material-ui/lab/Rating";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";

const customIcons: {
	[index: string]: { icon: React.ReactElement; label: string };
} = {
	1: {
		icon: <SentimentVeryDissatisfiedIcon fontSize="large" />,
		label: "Very Dissatisfied",
	},
	2: {
		icon: <SentimentDissatisfiedIcon fontSize="large" />,
		label: "Dissatisfied",
	},
	3: {
		icon: <SentimentSatisfiedIcon fontSize="large" />,
		label: "Neutral",
	},
	4: {
		icon: <SentimentSatisfiedAltIcon fontSize="large" />,
		label: "Satisfied",
	},
	5: {
		icon: <SentimentVerySatisfiedIcon fontSize="large" />,
		label: "Very Satisfied",
	},
};

function IconContainer(props: IconContainerProps) {
	const { value, ...other } = props;
	return <span {...other}>{customIcons[value].icon}</span>;
}

interface Props {
	feeling: number;
	setFeeling: (a: number) => void;
}

const FeedbackFeelingRating: FC<Props> = ({ setFeeling, feeling }) => {
	const classes = makeStyles((theme: Theme) =>
		createStyles({
			label: {
				marginBottom: theme.spacing(0.5),
			},
		})
	)();

	return (
		<div>
			<Typography component="legend" className={classes.label}>
				Wat is je gevoel bij deze feedback?
			</Typography>
			<Rating
				name="customized-icons"
				defaultValue={feeling}
				getLabelText={(value: number) => customIcons[value].label}
				IconContainerComponent={IconContainer}
				onChange={(event, newValue) => {
					setFeeling(newValue || 3);
				}}
			/>
		</div>
	);
};

export default FeedbackFeelingRating;
