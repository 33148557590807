import {
	SET_ACTIVE_MENU_ITEM,
	SET_USER,
	ADD_MENU_ITEMS,
	SET_ACTIVE_MENU_TRAIL,
} from "./actionTypes";

const initialState: State = {
	activeMenuItem: null,
	activeMenuTrail: [],
	user: null,
	menu: [],
};

const reducer = (state: State = initialState, action: any): State => {
	switch (action.type) {
		case SET_ACTIVE_MENU_ITEM:
			const { activeMenuItem } = action;
			return {
				...state,
				activeMenuItem,
			};

		case SET_ACTIVE_MENU_TRAIL:
			const { activeMenuTrail } = action;
			return {
				...state,
				activeMenuTrail,
			};
		case SET_USER:
			const { user } = action;
			return {
				...state,
				user,
			};

		case ADD_MENU_ITEMS:
			const { menuItems } = action;
			const menu = state.menu.concat(menuItems);

			return {
				...state,
				menu,
			};
	}
	return state;
};

export default reducer;
