import React, { FC, useCallback, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import DeleteIcon from "@material-ui/icons/Delete";
import SendIcon from "@material-ui/icons/Send";
import {
	Box,
	createStyles,
	makeStyles,
	Snackbar,
	TextField,
	Theme,
	Typography,
} from "@material-ui/core";
import FeedbackFeelingRating from "./FeedbackFeelingRating";
import FeedbackTypeButtons from "./FeedbackTypeButtons";
import { Alert } from "@material-ui/lab";
import { postFeedback } from "../../api/api";
import { takeScreenshotJpegBlob, blobToCanvas } from "./screenshot";

const Feedback: FC = () => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [feeling, setFeeling] = useState<number>(3);
	const [screenshot, setScreenshot] = useState<string | null>(null);
	const [message, setMessage] = useState<string>("");
	const [type, setType] = useState("question");
	const [snackbarAlert, setSnackBarAlert] = useState<JSX.Element | null>(null);
	const [messageError, setMessageError] = useState<string>("");
	const [showDialog, setShowDialog] = useState<boolean>(true);

	const handleClose = () => {
		setOpen(false);
		setScreenshot(null);
		setMessage("");
		setType("question");
		setFeeling(3);
	};

	const handleSnackbarClose = (
		event?: React.SyntheticEvent,
		reason?: string
	) => {
		if (reason === "clickaway") {
			return;
		}

		setSnackBarAlert(null);
	};

	const succesAlert = (
		<Alert
			onClose={handleSnackbarClose}
			severity="success"
			variant="filled"
			className={classes.alert}
		>
			Bedankt voor je feedback! Via de e-mail ontvang je hier nog een
			bevestiging van.
		</Alert>
	);

	const handleOnChangeMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value;
		const error = newValue.length ? "" : "Dit veld is verplicht";
		setMessageError(error);
		setMessage(newValue);
	};

	const errorAlert = (
		<Alert
			onClose={handleSnackbarClose}
			severity="error"
			variant="filled"
			className={classes.alert}
		>
			Er is is mis gegaan. Probeer het nog een keer en neem contact op met de
			admin als dit probleem nogmaals voorkomt.
		</Alert>
	);

	const handleClickOpen = async () => {
		setOpen(true);
	};

	const blobToBase64 = (blob: Blob): Promise<string | ArrayBuffer | null> => {
		const reader = new FileReader();
		reader.readAsDataURL(blob);
		return new Promise((resolve) => {
			reader.onloadend = () => {
				resolve(reader.result);
			};
		});
	};

	const takeScreenshot = async () => {
		setShowDialog(false);

		const screenshotBlob = await takeScreenshotJpegBlob().catch((error) => {
			console.log(error);
		});

		const base64 = await blobToBase64(screenshotBlob);
		base64 && setScreenshot(base64.toString());

		setShowDialog(true);
	};

	const handleOnSubmit = async () => {
		if (!message) {
			setMessageError("Dit veld is verplicht");
			return;
		}

		const data = { feeling, message, type, screenshot };
		const success = await postFeedback(data);

		if (success) {
			setMessageError("");
			handleClose();
			setSnackBarAlert(succesAlert);
		} else {
			setSnackBarAlert(errorAlert);
		}
	};

	return (
		<div>
			<Button
				variant="contained"
				color="primary"
				onClick={handleClickOpen}
				className={classes.feedbackButton}
			>
				Feedback
			</Button>
			{showDialog && (
				<Dialog
					open={open}
					onClose={handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						{"Geef je mening, stel een vraag of rapporteer hier een probleem!"}
					</DialogTitle>
					<DialogContent>
						<Box className={classes.questionBox}>
							<FeedbackFeelingRating
								setFeeling={setFeeling}
								feeling={feeling}
							/>
						</Box>

						<Box className={classes.questionBox}>
							<FeedbackTypeButtons type={type} setType={setType} />
						</Box>

						<Box className={classes.questionBox}>
							<TextField
								multiline
								required
								error={"" !== messageError}
								label={"Wat wil je met ons delen?"}
								helperText={messageError}
								defaultValue={message}
								rowsMax={8}
								className={classes.textArea}
								onChange={handleOnChangeMessage}
							/>
						</Box>
						<Box>
							<Typography component="legend" className={classes.label}>
								Het is voor ons handig dat we met je mee kunnen kijken. Deel
								daarom een screenshot door op de onderstaande knop te klikken.
							</Typography>
							{screenshot ? (
								<div className={classes.screenshotContainer}>
									<img
										src={screenshot}
										height="200px"
										className={classes.screenshot}
									/>
									<Button
										variant="contained"
										color="secondary"
										startIcon={<DeleteIcon />}
										onClick={() => setScreenshot(null)}
										className={classes.button}
									>
										Verwijder screenshot
									</Button>
								</div>
							) : (
								<Button
									variant="contained"
									color="primary"
									onClick={takeScreenshot}
									startIcon={<ScreenShareIcon />}
									className={classes.button}
								>
									Voeg screenshot toe
								</Button>
							)}
						</Box>
					</DialogContent>
					<DialogActions>
						<Button
							className={classes.button}
							onClick={handleOnSubmit}
							color="primary"
							autoFocus
							variant="contained"
							endIcon={<SendIcon />}
						>
							Verzend feedback
						</Button>
					</DialogActions>
				</Dialog>
			)}

			<Snackbar
				open={null !== snackbarAlert}
				autoHideDuration={6000}
				onClose={handleSnackbarClose}
			>
				{snackbarAlert || undefined}
			</Snackbar>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		feedbackButton: {
			borderRadius: "0",
			position: "fixed",
			right: "30%",
			textTransform: "none",
			top: "0",
		},
		questionBox: {
			marginBottom: theme.spacing(3),
		},
		textArea: {
			width: "100%",
		},
		label: {
			marginBottom: theme.spacing(1),
		},
		screenshotContainer: {
			alignItems: "center",
			display: "flex",
		},
		screenshot: { marginRight: theme.spacing(1) },
		button: {
			textTransform: "none",
		},
		alert: {
			fontSize: "1rem",
		},
	})
);

export default Feedback;
