import {
	createStyles,
	Grid,
	makeStyles,
	Paper,
	Theme,
} from "@material-ui/core";
import React from "react";

const NotFoundPage = () => {
	const classes = useStyles();
	return (
		<Grid container spacing={3} className={classes.root}>
			<Grid item xs={12}>
				<Paper className={classes.paper}>
					<h1>Pagina niet gevonden</h1>
				</Paper>
			</Grid>
		</Grid>
	);
};
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			minHeight: "100%",
			padding: theme.spacing(3),
		},
		paper: {
			padding: theme.spacing(3),
		},
	})
);
export default NotFoundPage;
