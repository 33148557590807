import React, { FC } from "react";
import { Link } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import { connect, ConnectedProps } from "react-redux";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";

const mapState = (state: UserState) => ({
	user: state.user,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;
const connector = connect(mapState);

const UserLink: FC<Props> = ({ user }) => {
	const classes = makeStyles((theme: Theme) =>
		createStyles({
			avatar: {
				marginRight: theme.spacing(2.25),
			},
			avatarImage: {
				height: "40px",
				width: "40px",
			},
			link: {
				alignItems: "center",
				color: theme.palette.primary.main,
				display: "flex",
				textDecoration: "none",
				"&visited": {
					color: theme.palette.primary.main,
				},
			},
			userDetails: {
				textAlign: "left",
			},
		})
	)();

	return (
		<Link to="/user" className={classes.link}>
			<Avatar aria-label="user" className={classes.avatar}>
				<img
					src={user?.image}
					alt={user?.preferedName}
					className={classes.avatarImage}
				/>
			</Avatar>
			<div className={classes.userDetails}>
				<div>
					<Typography variant="body1" color="textPrimary">
						{user?.preferedName}
					</Typography>
				</div>
				<div>
					<Typography variant="body1" color="textSecondary">
						{user?.emailAddress}
					</Typography>
				</div>
			</div>
		</Link>
	);
};

export default connector(UserLink);
