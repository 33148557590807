const UseHrefBuilder = () => {
	const hrefSwitch = (item: MainMenuItemInterface) =>
		({
			external: item.url,
			page: "/" === item.path ? item.path : `/${item.type}/${item.path}`,
			embed: `/${item.type}/${item.path}`,
			category: null,
		}[item.type]);

	return hrefSwitch;
};

export default UseHrefBuilder;
